import { format } from "date-fns"

import {
  IEstimate,
  LineItemEntityType,
  TEstimate,
  UTCSeconds,
} from "src/components/Account/types"
import {
  IAvailablePlan,
  ISubscriptionEstimateResponse,
  TCurrentSubscription,
} from "src/data/billing/types/billingTypes"
import { InvoiceTiming } from "src/data/subscription/types/subscriptionTypes"
import { TSubscriptionEstimates } from "src/data/subscription/types/subscriptionTypes"
import { PLAN, TPlan } from "src/data/user/user"

export interface PlanWithCostEstimate {
  availablePlan: IAvailablePlan
  cost: {
    plan: {
      quantity?: number
      unitPrice?: number
      total?: number
    }
    totalPrice: number
    estimate: ISubscriptionEstimateResponse | TSubscriptionEstimates
  }
}

export function centsToDollarPerMonth(unitPrice: number) {
  // minimize number of division to avoid floating point error
  return unitPrice / (12 * 100)
}

// TODO PRD-402: replace this with a proper change based on plan to translations
export function planToCopy(plan: TCurrentSubscription["plan"] | TPlan) {
  if (plan === "pro") {
    return "Pro"
  } else if (plan === "pro_plus") {
    return "Pro+"
  } else {
    return "Standard"
  }
}

export function getUpdateOptions({
  invoiceTiming,
  hasCouponIds,
}: {
  invoiceTiming: InvoiceTiming
  hasCouponIds?: boolean
}) {
  const updateOptions = ({
    endOfTerm,
    forceTermReset = false,
  }: {
    endOfTerm: boolean
    forceTermReset: boolean
  }) => ({
    reactivate: false,
    end_of_term: endOfTerm,
    invoice_immediately: !endOfTerm,
    force_term_reset: forceTermReset,
  })

  switch (invoiceTiming) {
    case "immediately":
      // If a coupon is added, force reset the term to make sure that the
      // discount is applied over a full term, as opposed to just the pro-rated
      // part that is left.
      return updateOptions({ endOfTerm: false, forceTermReset: !!hasCouponIds })
    case "end_of_term":
      return updateOptions({ endOfTerm: true, forceTermReset: false })
    default:
      throw new Error(
        `Unsupported invoiceTiming, it must be one of [immediately, end_of_term] but was ${invoiceTiming}`
      )
  }
}

export function createPlansWithCostEstimate({
  availablePlans,
  estimates,
}: {
  availablePlans: IAvailablePlan[]
  estimates: ISubscriptionEstimateResponse[] | TSubscriptionEstimates[]
}): PlanWithCostEstimate[] {
  const planIds = availablePlans.map((plan) => plan.plan_id)
  const planIdToAvailablePlan = availablePlans.reduce<{
    [key: string]: IAvailablePlan
  }>(
    (accumulator, plan) => ({
      ...accumulator,
      [plan.plan_id]: plan,
    }),
    {}
  )
  const entityIdToEstimate = estimates.reduce<{
    [key: string]: PlanWithCostEstimate["cost"]
  }>((accumulator, estimate) => {
    const planLineItem = estimate.estimate.invoice_estimate?.line_items?.find(
      (item) => item.entity_type === LineItemEntityType.PLAN
    )

    if (planLineItem && planLineItem.entity_id) {
      return {
        ...accumulator,
        [planLineItem.entity_id]: {
          plan: {
            unitPrice: planLineItem.unit_amount,
            quantity: planLineItem.quantity,
            total: planLineItem.amount,
          },
          totalPrice: estimate?.estimate.invoice_estimate?.total ?? 0,
          estimate: estimate,
        },
      }
    } else {
      throw new Error(
        "Could not find any plan Item or plan Item with entity_id in estimate"
      )
    }
  }, {})

  // @ts-expect-error: noUncheckedIndexedAccess
  return planIds.map((planId) => ({
    availablePlan: planIdToAvailablePlan[planId],
    cost: entityIdToEstimate[planId],
  }))
}

export function stringToPlan(plan: string | undefined): TPlan {
  if (plan === "pro") {
    return PLAN.pro
  } else if (plan === "pro_plus") {
    return PLAN.pro_plus
  } else if (plan === "standard") {
    return PLAN.standard
  }
  return PLAN.starter
}

export function formatUTCSecondsDate(date?: UTCSeconds) {
  return date ? format(new Date(Number(date) * 1000), "MMM do yyyy") : null
}

export function formatUTCStringDate(date?: string) {
  return date ? format(new Date(date), "MMM do yyyy") : null
}

export function getCouponIdsFromEstimate(
  estimate: IEstimate | TEstimate | undefined
) {
  if (!estimate) {
    return undefined
  }

  return (
    estimate.invoice_estimate?.discounts
      ?.filter((discount) =>
        ["document_level_coupon", "item_level_coupon"].includes(
          discount?.entity_type || ""
        )
      )
      ?.map((discount) => discount.entity_id)
      /** For all discounts of type document_level_coupon or item_level_coupon,
      chargebee returns an entity_id, which corresponds to the id of the
      coupon. This typeguard makes typescript understand this. */
      ?.filter((entityId): entityId is string => entityId !== undefined)
  )
}
